import React, { useContext, useState } from "react";
import Trans from "../helper/Trans";
import axiosInstance from "../helper/axios";
import DropDownList from "./DropDownList";
import DataLoading from "./DataLoading";
import UploadBC from "./UploadBC";
import { Link } from "react-router-dom";
import FormObjectionResponse from "./FormObjectionResponse";
import DocObjectionResponse from "./DocObjectionResponse";
import QuotaObjectionResponse from "./QuotaObjectionResponse";
import { UserContext } from "./UserContext";
import UploadBC2 from "./UploadBC2";

const attApi = axiosInstance;

function Applications(props) {
  const { siteInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);
  const [openBCModal, setOpenBCModal] = useState(false);

  const [banks2, setBanks2] = useState(false);
  const [openBankModal2, setOpenBankModal2] = useState(false);
  const [openBCModal2, setOpenBCModal2] = useState(false);
  const [selectedBank2, setSelectedBank2] = useState("");

  const [selectedBank, setSelectedBank] = useState("");
  const [errors, setErrors] = useState({});

  const [openFormResModal, setOpenFormResModal] = useState(false);
  const [openDocResModal, setOpenDocResModal] = useState(false);
  const [openQuotaResModal, setOpenQuotaResModal] = useState(false);

  const downloadRollNoSlip = (appId, slipId, type) => {
    setLoading(true);
    const axiosConf = {
      responseType: "arraybuffer",
    };
    attApi
      .post(
        "download-roll-no-slip?id=" + appId + "&slipId=" + slipId,
        null,
        axiosConf
      )
      .then((response) => {
        if (response.status === 200) {
          let fileName = type === "1" ? "RollNoSlip.pdf" : "AdmitCard.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadAdmissionChallan = (id) => {
    setOpenBankModal2(false);
    setOpenBCModal2(true);
  };

  const downloadOfferLetter = (id) => {
    setLoading(true);
    const axiosConf = {
      responseType: "arraybuffer",
    };
    attApi
      .post("download-offer-letter?id=" + id, null, axiosConf)
      .then((response) => {
        if (response.status === 200) {
          setOpenBankModal2(false);
          props.refreshAppData();
          let fileName = "OfferLetter.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadBankAdmissionChallan2 = (id, bankId) => {
    if (bankId === "") {
      const newErrors = {};
      newErrors["bank"] = errorMsg;
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    const axiosConf = {
      responseType: "arraybuffer",
    };
    attApi
      .post(
        "download-admission-bank-challan?id=" + id + "&bankId=" + bankId,
        null,
        axiosConf
      )
      .then((response) => {
        if (response.status === 200) {
          setOpenBankModal2(false);
          props.refreshAppData();
          let fileName = "AdmissionChallan.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadBankAdmissionChallan = (id) => {
    setLoading(true);
    attApi
      .get("get-admission-banks?id=" + id)
      .then((response) => {
        setSelectedBank2("");
        setBanks2(response.data.banks);
        if (Object.keys(response.data.banks).length === 1) {
          downloadBankAdmissionChallan2(
            id,
            Object.keys(response.data.banks)[0]
          );
        } else {
          setOpenBankModal2(true);
          setOpenBCModal2(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadBankChallan = (id) => {
    setLoading(true);
    attApi
      .get("get-application-banks?id=" + id)
      .then((response) => {
        setSelectedBank("");
        setBanks(response.data.banks);
        if (Object.keys(response.data.banks).length === 1) {
          downloadBankChallan2(id, Object.keys(response.data.banks)[0]);
        } else {
          setOpenBankModal(true);
          setOpenBCModal(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadForm = (appId) => {
    setLoading(true);
    const axiosConf = {
      responseType: "arraybuffer",
    };
    attApi
      .post("download-form?id=" + appId, null, axiosConf)
      .then((response) => {
        if (response.status === 200) {
          let fileName = "ApplicationForm.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadBankChallan = (id) => {
    setOpenBankModal(false);
    setOpenBCModal(true);
  };

  const errorMsg = Trans("Please select value.");
  const downloadBankChallan2 = (id, bankId) => {
    if (bankId === "") {
      const newErrors = {};
      newErrors["bank"] = errorMsg;
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    const axiosConf = {
      responseType: "arraybuffer",
    };
    attApi
      .post(
        "download-form-bank-challan?id=" + id + "&bankId=" + bankId,
        null,
        axiosConf
      )
      .then((response) => {
        if (response.status === 200) {
          setOpenBankModal(false);
          props.refreshAppData();
          let fileName = "BankChallan.pdf";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "application/pdf",
                encoding: "UTF-8",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectBank = (e) => {
    const { value } = e.target;
    setSelectedBank(value);
    const newErrors = {};
    setErrors(newErrors);
  };
  const selectBank2 = (e) => {
    const { value } = e.target;
    setSelectedBank2(value);
    const newErrors = {};
    setErrors(newErrors);
  };
  return (
    <div className="card mb-2">
      <div className="card-body">
        <h3>{Trans("Applications")}</h3>

        {props.data.map((dataItem, index) => (
          <div className=" mt-3" key={index}>
            <hr />
            <h4 className="mb-4">
              {dataItem.name}
              <button
                className="btn btn-outline-success float-right"
                onClick={() => {
                  downloadForm(dataItem.id);
                }}
              >
                {Trans("Form PDF")}
              </button>
            </h4>
            <div className="row">
              <div className="col-md-4  mb-3">
                <b>{Trans("Preferences")}:</b>
                <br />
                {Object.keys(dataItem.degrees).map((e, i) => {
                  return (
                    <div key={i}>
                      {dataItem.degrees[e]["preference_no"]}.{" "}
                      {dataItem.degrees[e]["name"]}
                      {dataItem.degrees[e]["status"] === 2 ||
                      dataItem.degrees[e]["status"] === 3 ? (
                        <div className="ml-3 mt-1">
                          <span className={"badge badge-danger text-14"}>
                            {dataItem.degrees[e]["status_desc"]}
                          </span>

                          <p>{dataItem.degrees[e]["status_reason"]}</p>
                          {dataItem.degrees[e]["status"] === 2 ? (
                            <>
                              {openQuotaResModal === false ? (
                                <button
                                  onClick={() => {
                                    setOpenQuotaResModal(true);
                                  }}
                                  className="btn btn-success"
                                >
                                  {Trans("Reply")}
                                </button>
                              ) : (
                                <></>
                              )}

                              {openQuotaResModal === true ? (
                                <QuotaObjectionResponse
                                  id={dataItem.degrees[e]["id"]}
                                  setOpenQuotaResModal={setOpenQuotaResModal}
                                  refreshAppData={props.refreshAppData}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}

                {dataItem.status === "1" && dataItem.rollNoSlip ? (
                  <>
                    {Object.keys(dataItem.rollNoSlip).map((e, i) => {
                      let rval = dataItem.rollNoSlip[e];
                      let rtype = rval.type === "T" ? "1" : "2";
                      let rname = rval.type === "T" ? "Test" : "Interview";
                      return (
                        <div key={i}>
                          <hr />
                          <b>
                            {Trans(rname) + " " + Trans("Roll No.")}{" "}
                          </b> : {rval.roll_no}
                          {rval.show_marks && rval.obtained_marks !== "" ? (
                            <div className="float-right">
                              <b>{Trans(rname) + " " + Trans("Marks")}: </b>
                              {rval.obtained_marks + "/" + rval.total_marks}
                            </div>
                          ) : (
                            <></>
                          )}
                          {rval.show_roll_no_slip ? (
                            <>
                              <br />
                              <button
                                className="mt-3 mr-2 btn btn-success"
                                onClick={() => {
                                  downloadRollNoSlip(
                                    dataItem.id,
                                    rval.slip_id,
                                    rtype
                                  );
                                }}
                              >
                                {rval.roll_no_slip_btn_name}
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-4  mb-3">
                <b>{Trans("Application No.")} </b>
                {dataItem.application_no}
                <br />
                <b>{Trans("Applied On")}: </b>
                {dataItem.app_date}
                <br />
                {dataItem.bc_no != null && dataItem.bc_no !== "" ? (
                  <>
                    <b>{Trans("Bank Challan No.")} </b>
                    {dataItem.bc_no}
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {dataItem.bc_img !== null &&
                dataItem.bc_img !== "" &&
                dataItem.paid_status !== "1" ? (
                  <img
                    src={process.env.REACT_APP_BACKEND_URL2 + dataItem.bc_img}
                    height="150px"
                    width="150px"
                    className={`thumbnail mt-2 `}
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </div>

              <div className="col-md-4 mb-3">
                <>
                  {Trans("Image Approval")}
                  <span
                    className={
                      dataItem.img_app === "1"
                        ? "badge badge-success ml-3 text-14 float-right"
                        : "badge badge-danger ml-3 text-14 float-right"
                    }
                  >
                    {dataItem.img_status_desc}
                  </span>
                  <p style={{ color: "red" }}>{dataItem.img_status_reason}</p>
                  {dataItem.img_app === "2" ? (
                    <Link className="btn btn-primary" to="/profile">
                      {Trans("Update")}
                    </Link>
                  ) : (
                    <></>
                  )}
                  <hr />
                </>

                {dataItem.bc_no != null && dataItem.bc_no !== "" ? (
                  <>
                    {Trans("Bank Challan Approval")}
                    <span
                      className={
                        dataItem.paid_status === "1"
                          ? "badge badge-success ml-3 text-14 float-right"
                          : "badge badge-danger ml-3 text-14 float-right"
                      }
                    >
                      {dataItem.paid_status_desc}
                    </span>
                    {dataItem.paid_comment ? (
                      <p style={{ color: "red" }}>{dataItem.paid_comment}</p>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {dataItem.paid_status !== "0" && dataItem.show_form_status ? (
                  <>
                    <hr />
                    {Trans("Form Approval")}
                    <span
                      className={
                        dataItem.approve_status === "1"
                          ? "badge badge-success ml-3 text-14 float-right"
                          : "badge badge-danger ml-3 text-14 float-right"
                      }
                    >
                      {dataItem.approve_status_desc}
                    </span>
                    {dataItem.app_reason ? (
                      <p style={{ color: "red" }}>{dataItem.app_reason}</p>
                    ) : (
                      <></>
                    )}
                    {dataItem.approve_status === "2" ? (
                      <>
                        {openFormResModal === false ? (
                          <button
                            onClick={() => {
                              setOpenFormResModal(true);
                            }}
                            className="btn btn-success"
                          >
                            {Trans("Reply")}
                          </button>
                        ) : (
                          <></>
                        )}

                        {openFormResModal === true ? (
                          <FormObjectionResponse
                            id={dataItem.id}
                            setOpenFormResModal={setOpenFormResModal}
                            refreshAppData={props.refreshAppData}
                          />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {siteInfo && siteInfo.take_academic_document === "1" ? (
                      <>
                        <hr />
                        {Trans("Document Approval")}
                        <span
                          className={
                            dataItem.doc_approve === "1"
                              ? "badge badge-success ml-3 text-14 float-right"
                              : "badge badge-danger ml-3 text-14 float-right"
                          }
                        >
                          {dataItem.doc_approve_desc}
                        </span>
                        {dataItem.doc_approve_reason ? (
                          <p style={{ color: "red" }}>
                            {dataItem.doc_approve_reason}
                          </p>
                        ) : (
                          <></>
                        )}
                        {dataItem.doc_approve === "2" ? (
                          <>
                            {openDocResModal === false ? (
                              <button
                                onClick={() => {
                                  setOpenDocResModal(true);
                                }}
                                className="btn btn-success"
                              >
                                {Trans("Reply")}
                              </button>
                            ) : (
                              <></>
                            )}

                            {openDocResModal === true ? (
                              <DocObjectionResponse
                                id={dataItem.id}
                                setOpenDocResModal={setOpenDocResModal}
                                refreshAppData={props.refreshAppData}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {dataItem.allow_update_test_challan === true ? (
                  <>
                    {dataItem.bc_no === null || dataItem.bc_no === "" ? (
                      <>
                        <button
                          className="mt-3 btn btn-success"
                          onClick={() => {
                            downloadBankChallan(dataItem.id);
                          }}
                        >
                          {Trans("Download Bank Challan")}
                        </button>
                        <br />
                      </>
                    ) : (
                      <>
                        {dataItem.bc_no !== "" &&
                        (dataItem.paid_status === "0" ||
                          dataItem.paid_status === "2") ? (
                          <>
                            <button
                              className="mt-3 mr-2 btn btn-success"
                              onClick={() => {
                                downloadBankChallan(dataItem.id);
                              }}
                            >
                              {Trans("Download Bank Challan")}
                            </button>
                            <button
                              className="mt-3 btn btn-primary"
                              onClick={() => {
                                uploadBankChallan(dataItem.id);
                              }}
                            >
                              {Trans("Upload Paid Bank Challan")}
                            </button>
                            <br />
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {dataItem.alloted === true ? (
              <>
                <hr />
                {dataItem.admission_paid === true ? (
                  <p>
                    You have paid admission fee for  a{" "}
                    <b>{dataItem.alloted_degree}</b>.
                  </p>
                ) : (
                  <>
                    <p>
                      Congratulations! You have been allotted a{" "}
                      <b>{dataItem.alloted_degree}</b> . Please download your{" "}
                      <b>offer letter</b>. If you agree to accept the admission,
                      please download and deposit the admission fee challan and
                      upload paid challan till <b>{dataItem.fee_due_date}</b>.{" "}
                    </p>
                    <p className="text-danger text-16">{dataItem.admission_fee_alert}</p>
                    <button
                      className="mr-2 btn btn-success mt-1"
                      onClick={() => {
                        downloadOfferLetter(dataItem.alloted_degree_id);
                      }}
                    >
                      {Trans("Offer Letter")}
                    </button>
                    <button
                      className="mr-2 btn btn-success mt-1"
                      onClick={() => {
                        downloadBankAdmissionChallan(
                          dataItem.alloted_degree_id
                        );
                      }}
                    >
                      {Trans("Download Admission Fee Challan")}
                    </button>
                    <button
                      className="btn btn-primary mt-1"
                      onClick={() => {
                        uploadAdmissionChallan(dataItem.alloted_degree_id);
                      }}
                    >
                      {Trans("Upload Paid Admission Fee Challan")}
                    </button>
                  </>
                )}

                <br />
              </>
            ) : (
              <></>
            )}
            {dataItem.form_comments === "" ? (
              ""
            ) : (
              <>
                <hr />
                <p>{dataItem.form_comments}</p>
              </>
            )}
            <div className="row">
              {loading ? (
                <DataLoading />
              ) : (
                <>
                  {openBankModal ? (
                    <div className="col-md-4">
                      <div className="card mt-3">
                        <div className="card-body">
                          <DropDownList
                            name="bank"
                            label={Trans("Select Bank")}
                            value={selectedBank}
                            handleInputChange={selectBank}
                            prompt={"-Select-"}
                            reqCols={[]}
                            listData={banks}
                            errors={errors}
                          />
                          <button
                            type="button"
                            className=" btn btn-primary mt-3 mr-2"
                            onClick={() => {
                              downloadBankChallan2(dataItem.id, selectedBank);
                            }}
                          >
                            {Trans("Select")}
                          </button>
                          <button
                            type="button"
                            className=" btn btn-danger mt-3"
                            onClick={() => {
                              setOpenBankModal(false);
                            }}
                          >
                            {Trans("Close")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {openBankModal2 ? (
                    <div className="col-md-6">
                      <div className="card mt-3">
                        <div className="card-body">
                          <DropDownList
                            name="bank"
                            label={Trans("Select Bank")}
                            value={selectedBank2}
                            handleInputChange={selectBank2}
                            prompt={"-Select-"}
                            reqCols={[]}
                            listData={banks2}
                            errors={errors}
                          />
                          <button
                            type="button"
                            className=" btn btn-primary mt-3 mr-2"
                            onClick={() => {
                              downloadBankAdmissionChallan2(
                                dataItem.alloted_degree_id,
                                selectedBank2
                              );
                            }}
                          >
                            {Trans("Select")}
                          </button>
                          <button
                            type="button"
                            className=" btn btn-danger mt-3"
                            onClick={() => {
                              setOpenBankModal2(false);
                            }}
                          >
                            {Trans("Close")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {openBCModal === true ? (
                    <UploadBC
                      data={dataItem}
                      setOpenBCModal={setOpenBCModal}
                      refreshAppData={props.refreshAppData}
                    />
                  ) : (
                    <></>
                  )}
                  {openBCModal2 === true ? (
                    <UploadBC2
                      data={dataItem}
                      setOpenBCModal={setOpenBCModal2}
                      refreshAppData={props.refreshAppData}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <br />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Applications;
